
import {computed, defineComponent, ref} from "vue";
import ReservationFlightForm from "@/components/reservations/parts/ReservationFlightForm.vue";

export default defineComponent({
    name: "new-reservation-flight-modal",
    props: {
        reservationid: {type:Number, required:true},
    },
    components: {
        ReservationFlightForm,
    }

});
