
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ReservationTable from "@/components/reservations/ListFlight.vue";


//
export default defineComponent({
  name: "hotel-settings",
  components: {
    ReservationTable,
  },
  props:{
    setValue: String,
    setCountry: String,
  },
  data: function () {
    return {
      myLoading: false,
    }
  },
  methods: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Bilet Rezervasyon Listesi", ["Rezervasyon", "Liste"]);
    });

    return {

    };
  },
});
