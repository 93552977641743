
import {computed, defineComponent, ref} from "vue";
import {Field} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

interface newInputs {
  parseInput: Array<any>;
}
export default defineComponent({
  name: "excel-parse-modal",
  components: {
    Field,
  },
  props: {
    prcType: String,
  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newExcelParseModalRef = ref<null | HTMLElement>(null);
    const newInputs = ref<newInputs>({
      parseInput: [],
    });

    const myDetails = {
      "FLIGHT_RESERVATION": [
        {
          Status: 'mandatory',
          Value: 'PAX',
          Title: 'PAX',
          Shorthand: 'PAX',
        },
        {
          Status: 'free',
          Value: 'GENDER',
          Title: 'Cinsiyet',
          Shorthand: 'GENDER',
        },

        {
          Status: 'free',
          Value: 'NAME',
          Title: 'İsim',
          Shorthand: 'NAME',
        },
        {
          Status: 'free',
          Value: 'SURNAME',
          Title: 'Soyisim',
          Shorthand: 'SURNAME',
        },
        {
          Status: 'free',
          Value: 'BDATE',
          Title: 'Doğum Tarihi',
          Shorthand: 'BDATE',
        },
        {
          Status: 'free',
          Value: 'IDNO',
          Title: 'Kimlik No',
          Shorthand: 'IDNO',
        },
        {
          Status: 'free',
          Value: 'TICKETNO',
          Title: 'Bilet Numarası',
          Shorthand: 'TICKETNO',
        },
        {
          Status: 'free',
          Value: 'RTICKETNO',
          Title: 'Dönüş Bilet Numarası',
          Shorthand: 'RTICKETNO',
        },
      ],
    };
    const myTitleList = computed(() => {
      return store.getters.excelParseTitleList;
    });
    const submitParse = () => {
      store.dispatch(Actions.EXCEL_PARSE_RESULT, newInputs.value.parseInput);
    };
    return {
      myDetails,
      myTitleList,
      newInputs,
      submitParse,
    };
  },
});
