

import {computed, defineComponent, onMounted, ref} from "vue";
import {Field} from "vee-validate";
import store from "@/store";
import MasterConfig from "@/core/config/MasterConfig";
import {dateConvert} from "@/core/filters/datatime"
import NewReservationFlight from "@/components/reservations/NewReservationFlight.vue";
import {commonf} from "@/core/filters/common";
import {Turkish} from "flatpickr/dist/l10n/tr";
import flatpickr from "flatpickr";
import {Actions} from "@/store/enums/StoreEnums";


interface srcItem {
    srcFromDate: string;
    srcToDate: string;
    srcFlightFromDate: string;
    srcFlightToDate: string;
    srcAccount: number;
    srcDepartment: number,
    srcMarket: number;
    srcPNR: string;
    srcVoucherNo: string;
    srcAccountVoucherNo: string;
    srcName: string;
    srcLastName: string;
}

export default defineComponent({
    name: "hotel-list",
    components: {
        Field,
        NewReservationFlight,
    },
    data: function () {
        return {
            imgUrl: '',
            searchName: '',
            sortField: '',
            sortType: '',
            filterField: ['Title'],
            filterFieldName: ['İsim'],
        }
    },
    props: {
        widgetClasses: String,
    },
    computed: {

        filteredTableList: function () {
            if (this.myList.data !== undefined) {
                return this.myList.data.list;
                /*
                return this.myList.data.filter(filteredList =>
                    1==1
                      //  (filteredList.Title !== undefined && !(filteredList.Title).toLowerCase().indexOf(this.searchName.toLowerCase()))
                    //!(systemuser.Lastname).toLowerCase().indexOf(this.searchName.toLowerCase()) ||
                    //!(systemuser.Username).toLowerCase().indexOf(this.searchName.toLowerCase()) ||
                    //!(systemuser.Email).toLowerCase().indexOf(this.searchName.toLowerCase()) ||
                    //!(systemuser.Mobile).toLowerCase().indexOf(this.searchName.toLowerCase())
                );

                 */
            } else {
                return [];
            }
        },

    },
    methods: {
        ...dateConvert.method,

        sortData(attr, data) {
            if (this.sortField == '') {
                this.sortType = "asc";
            } else {
                this.sortType = this.sortField == attr && this.sortType == 'asc' ? "desc" : "asc";
            }
            this.sortField = attr;

            if (this.sortType == 'asc' || this.sortType == 'desc') {
                this[data].sort((a, b) => {
                    let fa = '';
                    let fb = '';
                    if (typeof (attr) == 'string') {
                        if (this.sortType == 'asc') {
                            fa = a[attr].toLowerCase();
                            fb = b[attr].toLowerCase();
                        } else {
                            fb = a[attr].toLowerCase();
                            fa = b[attr].toLowerCase();
                        }
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    } else {
                        return this.sortType == 'asc' ? (a.Mobile - b.Mobile) : (b.Mobile - a.Mobile);
                    }
                });
            }
        }
    },
    setup() {
        onMounted(() => {
            setDatePicker()
            resSearch();
        });
        const srcItem = ref<srcItem>({
            srcFromDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
            srcToDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
            srcFlightFromDate: "",
            srcFlightToDate: "",
            srcAccount: 0,
            srcDepartment: 0,
            srcMarket: 0,
            srcPNR: "",
            srcVoucherNo: "",
            srcAccountVoucherNo: "",
            srcName: "",
            srcLastName: "",
        });

        //store.dispatch(Actions.RESERVATIONS_LIST, {});
        const myList = computed(() => {
            return store.getters.reservationsList;
        });

        store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
        const myDepartments = computed(() => {
            return store.getters.DepartmentsList;
        });

        store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });

        function setDatePicker() {
            let myConfigS = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                defaultDate: 'today',
            };
            let myConfigF = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
            };

            let tmpSrcFromDate = flatpickr("#srcFromDate", myConfigS);
            let tmpSrcToDate = flatpickr("#srcToDate", myConfigS);
            let tmpSrcFlightFromDate = flatpickr("#srcFlightFromDate", myConfigF);
            let tmpSrcFlightToDate = flatpickr("#srcFlightToDate", myConfigF);
        }

        const myAccountList = computed(() => {
            return store.getters.accountList;
        });
        const myAccountsTitle = computed(() => {
            let tmpArray = [];
            if (myAccountList.value.data !== undefined) {
                for (var i of myAccountList.value.data) {
                    tmpArray.push(i.Title);
                }
            }
            return tmpArray;
        });

        const getAccounts = () => {
            let myAccounts = document.getElementById("srcAccounts") as HTMLInputElement;
            let myPayload = {};
            if (myAccounts.value.length == 3) {
                myPayload = {
                    'Title': myAccounts.value
                }
                store.dispatch(Actions.ACCOUNT_LIST_ALL, myPayload);
            }
        }
        const filterAccounts = (val) => {
            if (myAccountList.value.data != null) {
                return (myAccountList.value.data).filter(Items =>
                    (Items.Title == val)
                );
            } else {
                return true;
            }
        };
        const resSearch = () => {
            let myAccount = document.getElementById("srcAccounts") as HTMLInputElement;
            console.log(myAccount)
            const payload1 = {
                ProcessDateStart: srcItem.value.srcFromDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFromDate) : "",
                ProcessDateFinish: srcItem.value.srcToDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcToDate) : "",
                StartDateStart: srcItem.value.srcFlightFromDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFlightFromDate) : "",
                StartDateFinish: srcItem.value.srcFlightToDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFlightToDate) : "",
                VoucherNo: srcItem.value.srcVoucherNo,
                AccountVoucherNo: srcItem.value.srcAccountVoucherNo,
                Department: srcItem.value.srcDepartment,
                Market: srcItem.value.srcMarket,
                AccountID: myAccount.value ? filterAccounts(myAccount.value)[0].ID : "",
                PNR: srcItem.value.srcPNR,
                PaxName: srcItem.value.srcName,
                PaxLastName: srcItem.value.srcLastName,
                ProductType: "FLIGHT",
                is_active: "active",
            }

            console.log(payload1);
            store.dispatch(Actions.RESERVATIONS_LIST, payload1);
        }
        const myParams = computed(() => {
            return store.getters.generalParams;
        });
        //console.log(MasterConfig);
        return {
            myList,
            srcItem,
            myParams,
            MasterConfig,
            getAccounts,
            resSearch,
            myAccountsTitle,
            myDepartments,
            myMarkets,
            commonf,
            dateConvert,
        }
    },
});
